<template>
  <div v-loading="loading" class="questions-container">
    <div class="operate">
      <div class="sbuject">
        选择科目:
        <a-select
          v-model="subjectId"
          style="width: 120px"
          @change="handleChange"
        >
          <a-select-option
            v-for="(item, index) in subjectList"
            :key="index"
            :value="item.subjectId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <a-popover placement="bottomRight" trigger="click">
        <template slot="content">
          <div class="question-content">
            <div class="title">客观题</div>
            <div v-if="question1.length" class="questions">
              <div
                v-for="(item, index) in question1"
                :key="index + 'ques1'"
                class="question"
              >
                <div class="left">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ item.order }}</span>
                    </template>
                    <span>{{ item.order }}</span>
                  </a-tooltip>
                </div>
                <div class="right">
                  <a-icon
                    style="color: #2474ed"
                    type="plus-circle"
                    @click="add(item, index, 'question1')"
                  />
                </div>
              </div>
            </div>
            <no-data v-else />
          </div>
          <div class="question-content">
            <div class="title">主观题</div>
            <div v-if="question2.length" class="questions">
              <div
                v-for="(item, index) in question2"
                :key="index + 'ques2'"
                class="question"
              >
                <div class="left">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ item.order }}</span>
                    </template>
                    <span>{{ item.order }}</span>
                  </a-tooltip>
                </div>
                <div class="right">
                  <a-icon
                    style="color: #2474ed"
                    type="plus-circle"
                    @click="add(item, index, 'question2')"
                  />
                </div>
              </div>
            </div>
            <no-data v-else />
          </div>
        </template>
        <a-button type="primary">显示小题</a-button>
      </a-popover>
    </div>
    <a-form-model ref="form" :model="form">
      <a-table
        :data-source="form.statQuestionTypes"
        :columns="columns"
        :custom-row="rowClick"
        :row-class-name="rowClassName"
        :row-key="(record, index) => index"
        bordered
      >
        <template slot="order" slot-scope="text, record, index">
          <div class="order-pointer" @click="sortItem(record, index)">
            <img src="@/assets/uExam/order.png" alt="" />
          </div>
        </template>
        <template slot="name" slot-scope="text, record, index">
          <a-form-model-item
            :prop="'statQuestionTypes.' + index + '.name'"
            :rules="[
              {
                validator: (rule, value, callback) =>
                  validName(rule, value, callback, index),
                trigger: 'blur',
              },
            ]"
          >
            <a-input v-model="record.name"></a-input>
          </a-form-model-item>
        </template>
        <template slot="questionOrders" slot-scope="text, record, index">
          <div class="questions">
            <div
              v-for="(item, ind) in record.questionOrders"
              :key="ind"
              class="question"
            >
              <div class="left">{{ item }}</div>
              <div class="right" @click="delOder(record, index, item, ind)">
                <img src="@/assets/uExam/trash.png" alt="" />
              </div>
            </div>
          </div>
        </template>
        <template slot="actions" slot-scope="text, record, index">
          <a-button
            type="link"
            style="color: #f56c6b"
            @click="deleteItem(record, index)"
            >删除大题</a-button
          >
        </template>
        <template slot="footer">
          <div class="footer">
            <a-button type="link" @click="addQuestion"
              ><a-icon type="plus-circle" />添加大题</a-button
            >
          </div>
        </template>
      </a-table>
    </a-form-model>
    <div class="submit">
      <a-button type="primary" @click="submit">保存</a-button>
    </div>
  </div>
</template>

<script>
import { getstatinfo } from "@/core/api/academic/common";
import {
  getstatquestiontypesetting,
  setstatquestiontypesetting,
} from "@/core/api/academic/union";
import noData from "@/components/noData.vue";
export default {
  name: "",
  components: { noData },
  data() {
    return {
      subjectList: [],
      subjectId: null,
      tableData: [],
      loading: false,
      columns: [
        {
          title: "排序",
          align: "center",
          key: "order",
          width: 80,
          scopedSlots: { customRender: "order" },
        },
        {
          title: "大题名称",
          align: "center",
          key: "name",
          width: 200,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "所含小题",
          align: "center",
          key: "questionOrders",
          scopedSlots: { customRender: "questionOrders" },
        },
        {
          title: "操作",
          align: "center",
          key: "actions",
          width: 120,
          scopedSlots: { customRender: "actions" },
        },
      ],
      form: {
        statQuestionTypes: [],
      },
      remainingQuestions: [],
      questions: [],
      question1: [],
      question2: [],
      tableIndex: 0,
      is_check: false,
    };
  },
  created() {
    this.getStatInfoData();
  },
  methods: {
    async getStatInfoData() {
      const res = await getstatinfo({ id: this.$route.query.id });
      this.subjectList = res.data.data.statSubjects.filter(
        (item) => item.type == 1
      );
      if (this.subjectList.length) {
        this.subjectId = this.subjectList[0].subjectId;
      }
      this.getData();
    },
    validName(rule, value, callback, index) {
      let data = this.form.statQuestionTypes[index].name;
      if (!data) {
        callback(new Error("请输入答题名称"));
      } else callback();
    },
    sortItem(record, index) {
      if (index !== 0) {
        let arr = [...this.form.statQuestionTypes];
        let item = arr[index - 1];
        arr[index - 1] = {
          ...arr[index],
        };
        arr[index] = { ...item };
        this.form.statQuestionTypes = [...arr];
      }
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.tableIndex = index;
          },
        },
      };
    },
    rowClassName(record, index) {
      let className = "";
      if (index == this.tableIndex) {
        className = "questions-row-active";
        this.is_check = true;
        return className;
      }
    },
    add(item, index, feild) {
      if (this.form.statQuestionTypes.length === 0) {
        this.$message({
          showClose: true,
          message: "请先添加大题",
          type: "warning",
        });
        return false;
      }
      if (index === null) {
        this.$message({
          showClose: true,
          message: "请选择题目",
          type: "warning",
        });
        return false;
      }
      this[feild].splice(index, 1);
      this.form.statQuestionTypes[this.tableIndex].questionOrders.push(
        item.order
      );
      this.$forceUpdate();
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getstatquestiontypesetting({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
        if (res.data.data.remainingQuestions.length) {
          this.remainingQuestions = [...res.data.data.remainingQuestions];
        }
        this.question1 = this.remainingQuestions.filter(
          (item) => item.type === 0
        );
        this.question2 = this.remainingQuestions.filter(
          (item) => item.type === 1
        );
        this.questions = res.data.data.questions;
        this.form.statQuestionTypes = res.data.data.statQuestionTypes.map(
          (item) => ({
            ...item,
            questionOrders: item.questionOrders.split("###"),
          })
        );
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    delOder(record, index, item, ind) {
      this.form.statQuestionTypes[index].questionOrders.splice(ind, 1);
      let question = this.questions.find((it) => it.order == item);
      if (question) {
        if (question.type === 0) {
          this.question1.push({ ...question });
        } else if (question.type === 1) {
          this.question2.push({ ...question });
        }
      }

      this.$forceUpdate();
    },
    addQuestion() {
      this.form.statQuestionTypes.push({
        name: undefined,
        statId: 8105,
        questionOrders: [],
        subjectId: this.subjectId,
      });
    },
    handleChange() {
      this.getData();
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let params = {
              id: this.$route.query.id,
              subjectId: this.subjectId,
              statQuestionTypes: this.form.statQuestionTypes.map((item) => ({
                name: item.name,
                questionOrders: item.questionOrders.length
                  ? item.questionOrders.join("###")
                  : "",
              })),
            };
            await setstatquestiontypesetting(params);
            this.loading = false;
            this.getData();
            this.$message({
              showClose: true,
              message: "提交成功",
              type: "success",
            });
          } catch {
            this.loading = false;
          }
        }
      });
    },
    deleteItem(record, index) {
      if (record.questionOrders.length) {
        this.$confirm("该大题下包含小题，请先移除后再试。", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      } else {
        this.form.statQuestionTypes.splice(index, 1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.questions-container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}

.questions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .question {
    display: flex;
    align-items: center;
    height: 32px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #dee0e7;
    margin-right: 8px;
    margin-bottom: 8px;
    .left {
      box-sizing: border-box;
      padding: 0 8px;
      border-right: 1px solid #dee0e7;
      height: 100%;
      line-height: 32px;
    }
    .right {
      box-sizing: border-box;
      height: 100%;
      line-height: 32px;
      padding: 0 8px;
      cursor: pointer;
    }
  }
}
.question-content {
  .title {
    color: #0a1119;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .questions {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .question {
      display: flex;
      align-items: center;
      height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #dee0e7;
      margin-right: 8px;
      margin-bottom: 8px;
      .left {
        box-sizing: border-box;
        width: 85px;
        padding: 0 8px;
        border-right: 1px solid #dee0e7;
        height: 100%;
        line-height: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .right {
        box-sizing: border-box;
        height: 100%;
        line-height: 32px;
        padding: 0 8px;
        cursor: pointer;
      }
    }
  }
}
.footer {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px;
}
::v-deep .ant-table-footer {
  padding: 0 !important;
}
.submit {
  text-align: center;
  margin-top: 18px;
}
::v-deep .ant-form-explain {
  font-size: 12px !important;
}
::v-deep .ant-form-item-with-help {
  margin-bottom: 0;
}
::v-deep .ant-form-item {
  margin-bottom: 0;
}
</style>
<style>
.questions-row-active {
  background: #f5f7fa !important;
  /* border-color: #2474ed !important; */
}
.order-pointer {
  cursor: pointer;
}
</style>
